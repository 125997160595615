.SongWriterListPage {
    font-size: 12px;

    td {
        vertical-align: middle;
    }

    &-not-registered {
        color: var(--gray-600);

        .WarningGlyphIcon {
            margin-left: 4px;
            color: var(--glyphs-warning);
        }
    }

    &-pill {
        margin: 5px;
    }

    &-pill-zero {
        margin: 0 15px;
    }

    &-link-list {
        max-height: 200px;
    }
    &-link {
        cursor: pointer;
        color: var(--blue);
    }
    &-pka {
        color: var(--gray-600);
        font-size: 11px;
    }
    &-legal-name-column {
        width: 25%;
    }
}
