.EmptyState {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    flex-direction: column;

    &-header {
        font-size: 18px;
        margin-bottom: 10px;
    }

    &-sub-header {
        color: var(--gray-600);
    }
}
