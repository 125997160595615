.ReadError {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 10%;

    .Outline.SearchResults {
        margin-bottom: 30px;
    }

    &-title {
        font-size: 24px;
    }

    &-subtitle {
        color: var(--gray-700);
        margin-top: 15px;
    }
}
