.SelectExistingSong {
    &-title {
        font-size: 32px;
        text-align: center;
        margin-bottom: 25px;
    }

    &-section-title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 22px;
    }

    .card:not(:last-child) {
        margin-bottom: 24px;
    }

    &-no-existing {
        color: var(--blue);
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;

        .ArrowRight {
            fill: var(--blue);
            margin-left: 4px;
        }
    }

    &-search-input {
        margin-bottom: 20px;
    }

    .LoadingIndicator {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    &-table-img {
        margin-right: 12px;
    }

    &-table-upc {
        font-size: 12px;
        color: var(--gray-500);
    }

    &-table-name {
        font-size: 12px;
        color: var(--gray-500);
    }

    &-table-track {
        display: flex;
        align-items: center;
    }

    &-table-more-products {
        text-decoration: underline;
    }

    .table tr:hover {
        box-shadow: inset 0 0 10px 1px var(--gray-300);
        cursor: pointer;
    }
}
