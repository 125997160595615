.SongListPage {
    font-size: 12px;

    /* stylelint-disable-next-line selector-id-pattern */
    #NewSongButton {
        span {
            font-size: larger;
        }
    }

    td {
        vertical-align: middle;
    }

    &-tag {
        display: inline-flex;
        background-color: var(--gray-200);
        padding-left: 8px;
        padding-right: 8px;
        line-height: 18px;
        margin-right: 7px;
        border-radius: 10px;
    }

    &-tag-uncontrolled {
        color: var(--gray-600);
    }

    &-link {
        color: var(--blue);
        cursor: pointer;
    }

    &-none {
        color: var(--gray-500);
    }

    &-ownership {
        text-transform: lowercase;
    }

    &-more-link {
        color: var(--blue);
    }

    &-more-link-word {
        text-transform: lowercase;
    }

    .PageNav .nav-tabs {
        margin-right: auto;
    }
}
