.LabelSearchDropdown {
    color: var(--gray-900);
    max-width: 100%;

    &.hide {
        display: none;
    }

    &-option {
        overflow: hidden;

        &-name {
            float: left;
        }

        &-id {
            float: right;
        }
    }

    &-account-name {
        margin-left: 0.8rem;
        color: $gray-500;
        font-size: 0.875rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    &-account-id {
        float: right;
        color: $gray-500;
        font-size: 0.875rem;
        font-weight: 500;
        margin-right: 0.8rem;
        text-transform: uppercase;
    }
}
