.BreadcrumbContent {
    display: flex;

    .breadcrumb-item span {
        display: inline-block;
        max-width: 35vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .breadcrumb a::after {
        vertical-align: top;
    }
}
