.PageContent,
.PageControls {
    margin-left: 16px;
    margin-right: 16px;
}

.PageControls {
    margin-bottom: 24px;
}

.breadcrumb {
    font-size: 12px;
}

.card:not(:last-child) {
    margin-bottom: 32px;
}

@mixin header {
    background-color: white;
    border-bottom: 1px solid var(--gray-100);
    /* stylelint-disable-next-line color-function-notation */
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 20%);
    margin: -15px -15px 15px;
    padding-left: 25px;
    padding-right: 25px;
    position: sticky;
    z-index: 3;
}

.AppHeader {
    @include header;

    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-left: 20px;
    }
}

.AppHeaderTabs {
    @include header;

    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tab-content {
        display: none;
    }
}

.AppHeaderTabsTop {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: space-between;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.DropdownIconButton {
    .btn,
    .btn-link {
        .OptionsHorizontalGlyphIcon {
            color: var(--gray-800);
            vertical-align: baseline;
        }

        background: transparent;
        border: 0;
        padding: $input-btn-padding-y;
        border-radius: 100px;
        width: 35px;
        height: 35px;

        &:hover,
        &:focus,
        &[aria-expanded='true'] {
            background: $gray-200;
            box-shadow: 0 0 0 0.2rem hsl(15deg 4% 80% / 50%);
            color: var(--gray-400);
        }

        &:active,
        &.active,
        &:active:not(:disabled):not(.disabled) {
            box-shadow: none;
        }
    }
}
