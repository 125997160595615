.NewSongWriter {
    &-label {
        @include fonttype-label;

        margin-bottom: 10px;
    }

    &-value {
        margin-bottom: 22px;
    }
}

.SongWriterManager {
    &-new-songwriter-modal-subtitle {
        margin-bottom: 30px;
    }

    &-register-new-songwriter-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &-not-registered-message {
        background-color: rgba($yellow, 0.2);
        padding: 10px 15px;
        display: flex;

        .WarningGlyphIcon {
            fill: var(--yellow);
            height: 24px;
            width: 24px;
        }
    }

    &-not-registered-message-title {
        color: var(--gray-700);
        font-weight: bold;
        margin-bottom: 12px;
    }

    &-not-registered-message-subtitle {
        font-size: 12px;
    }

    &-not-registered-message-warning {
        margin-right: 20px;
    }

    &-clear-link {
        cursor: pointer;
        color: var(--blue);
        margin-left: 8px;
        display: inline-block;
    }

    &.save-disabled {
        .btn-primary {
            cursor: not-allowed;
        }
    }

    .TerritoriesList {
        margin-top: 20px;
    }

    .ContinentItem-container {
        background-color: var(--white);
        border: 1px solid var(--gray-400);
    }

    .TerritoriesList
        .ContinentItem:first-child
        .ContinentItem-container:not(.ContinentItem-country) {
        border: 1px solid var(--gray-400);
    }

    .ContinentItem-label {
        font-weight: normal;
    }

    .ContinentItem-label input {
        margin-right: 10px;
    }

    .ContinentItem-country {
        margin-right: 0;
    }

    .AddPublisherComponent {
        .header {
            display: flex;
        }
    }

    .PublishersComponent {
        .publisher {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .header {
                display: flex;
                justify-content: space-between;
            }

            .details {
                display: flex;

                * {
                    margin-right: 16px;
                }
            }
        }

        .FormField {
            flex: 1;
        }

        .deletePublisher {
            align-self: flex-start;
            width: 32px;
            cursor: pointer;
            text-align: right;
        }
    }

    &-pka-field-control {
        flex: 1;
        margin-bottom: 10px;
    }

    &-pka-field {
        display: flex;
        align-items: center;
    }

    &-pka-field-x {
        margin: 15px 0 0 8px;
        cursor: pointer;
        align-self: start;

        .Close:hover {
            fill: var(--gray-900);
        }
    }
}
