.SongWriterSelector {
    &-addNewSongwriter {
        color: var(--blue);

        .Add {
            fill: var(--blue);
            margin-right: 5px;
        }
    }

    .Select__clear-indicator {
        svg {
            background-color: var(--gray-400);
            fill: var(--white) !important;
            border-radius: 20px;
            height: 16px;
            width: 16px;

            &:hover {
                background-color: var(--gray-500);
            }
        }
    }
}

.was-validated {
    .FRCControl-error {
        .SongWriterSelector {
            .Select__control {
                border-color: red;
            }
        }
    }
}
