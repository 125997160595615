.NewSong {
    &-title {
        font-size: 32px;
        text-align: center;
        margin-bottom: 25px;
    }

    &-header-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &-header-subtitle {
        color: var(--gray-500);
        font-size: 12px;
        margin-bottom: 5px;
    }

    &-header {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--gray-300);
        margin-bottom: 16px;
    }

    &-alternate-field {
        display: flex;
        align-items: center;
    }

    &-alternate-field-control {
        flex: 1;
        margin-bottom: 20px;
    }

    &-alternate-field-x {
        margin: 15px 0 0 8px;
        cursor: pointer;
        align-self: start;

        .Close:hover {
            fill: var(--gray-900);
        }
    }

    &-songwriters-grid {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 130px 130px 30px;
        gap: 16px 20px;
        align-items: center;
        margin-bottom: 16px;
    }

    &-songwriters-grid-pub-column {
        grid-template-columns: 2fr 1fr 1fr 130px 130px 130px 30px;
    }

    &-divider {
        border-bottom: 1px solid var(--gray-400);
    }

    &-songwriters-total-ownership {
        color: var(--gray-500);
        font-size: 14px;
        text-align: right;
        margin-top: 12px;
    }

    &-songwriters-total-ownership > .unbound {
        color: $red;
    }

    &-songwriters-total-ownership > .bound {
        color: $green;
    }

    &-songwriters-name,
    &-songwriters-ownership,
    &-songwriters-contribution,
    &-songwriters-publicDomainSampling {
        align-self: start;
    }

    &-songwriters-pro,
    &-songwriters-ipi {
        align-self: center;
    }

    &-songwriters-contribution,
    &-songwriters-publicDomainSampling {
        margin: 5px 0;
    }

    &-sound-recording {
        /* stylelint-disable-next-line color-function-notation */
        box-shadow: 0 1px 4px rgba(0, 0, 0, 10%);
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &-sound-recording-remove {
        cursor: pointer;
        justify-self: right;
    }

    &-sound-recording-search {
        background-color: var(--gray-100);
        padding: 16px;
    }

    &-sound-recording-search-title {
        font-size: 16px;
        margin-bottom: 16px;
    }

    &-sound-recordings {
        margin-bottom: 100px;
    }

    &-sound-recordings-search-image {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }

    &-sound-recordings-search-image-placeholder {
        width: 30px;
        height: 30px;
        margin-right: 12px;
        background-color: var(--gray-400);
    }

    &-sound-recordings-search-row-left {
        display: flex;
        align-items: center;
    }

    &-sound-recordings-search-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-left: 14px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 50px;
    }

    &-sound-recordings-search-row:hover {
        box-shadow: inset 0 0 10px 1px var(--gray-300);
        cursor: pointer;
    }

    &-sound-recordings-search-results {
        background-color: var(--white);
    }

    &-sound-recordings-search-no-results {
        margin-top: 8px;
        background-color: var(--gray-100);
    }

    .Alert {
        .justify-content-between {
            a {
                margin-left: 10px;

                .btn {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.was-validated {
    .FRCControl-error {
        .LabelSearchDropDown {
            .Select__control {
                border-color: red;
            }
        }
    }
}
