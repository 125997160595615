/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin center_vertical {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
}

@mixin spinner($size) {
    animation: spinner 0.75s infinite linear;
    border: 3px solid var(--gray-200);
    border-left: 3px solid var(--blue);
    border-radius: 50%;
    height: $size;
    width: $size;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
