.ViewPublisher {
    &-label {
        @include fonttype-label;

        margin-bottom: 10px;
    }

    &-value {
        margin-bottom: 22px;
    }

    &-header {
        margin-bottom: 10px;
        font-weight: bold;
    }

    &-link {
        cursor: pointer;
        color: var(--blue);
        margin-bottom: 10px;
    }

    &-block {
        margin: 40px 0;
    }
}
